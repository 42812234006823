import React, { useEffect, useState } from 'react'
import './Filter.css'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory, useLocation } from 'react-router-dom'
import CheckBox from '../../atoms/CheckBox'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))

export default function Filter(props) {
    const history = useHistory()
    const location = useLocation()

    const handlechanges = (e) => {
        console.log('array===>', props)
        let query = new URLSearchParams(location.search)
        if (e.target.checked === true) {
            if (e.target.name === 'Origin') {
                let catarr = [...props.cat_id, e.target.value]
                query.set('cat_id', catarr.toString())
            } else {
                let arr = [...props.sub_cat_id, e.target.value]
                query.set('sub_cat_id', arr.toString())
            }
        } else {
            let arr = [...props.sub_cat_id, e.target.value]
            query.set('sub_cat_id', arr.filter((data) => data !== e.target.value).toString())
        }
        // query.set(e.target.name, arr.toString())
        history.push({ pathname: '/search', search: '?' + query.toString() })
    }

    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <div className={`${classes.root} filter`}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>{props.title}</Typography>
                    {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                </AccordionSummary>

                <AccordionDetails>
                    {Array.isArray(props.label) &&
                        props.label.map((data) => (
                            <Typography>
                                <CheckBox
                                    label={data.name}
                                    onchange={handlechanges}
                                    name={props.title}
                                    value={data.id}
                                    disable_filter={props.disabled_filter}
                                />
                            </Typography>
                        ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
