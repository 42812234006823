import React, { useState } from 'react'
import { removeHTMLTags } from '../../../common/components'

export const ReadMore = ({ data, limit, ...rest }) => {
    let cleanedData = removeHTMLTags(data)
    const [readMore, setReadMore] = useState(false)
    const toggleReadMore = () => {
        setReadMore(!readMore)
    }
    return (
        <>
            <p className={`${rest.className || ''}${readMore ? ' show' : ''}`}>
                {rest.showOriginalOnExpand ? (
                    <>
                        {!readMore ? (
                            cleanedData.substring(0, readMore ? cleanedData.length : limit)
                        ) : (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: data,
                                }}
                            />
                        )}

                        {!readMore && cleanedData.length > limit ? '...' : null}
                    </>
                ) : (
                    <>
                        {cleanedData.substring(0, readMore ? cleanedData.length : limit)}
                        {!readMore && cleanedData.length > limit ? '...' : null}
                    </>
                )}
            </p>
            {cleanedData.length > limit && (
                <a className="readMore cursorDecoy" onClick={toggleReadMore}>
                    {rest.label || 'See'} {readMore ? 'less' : 'more'}
                </a>
            )}
        </>
    )
}

export default ReadMore
