import React from 'react'
import TextField from '@material-ui/core/TextField'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className="customInput">
            <TextField
                value={props.value}
                autoFocus={props.autoFocus}
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
                InputProps={{
                    inputProps: props.inputProps,
                    startAdornment: props.startAdornment,
                    endAdornment: props.endAdornment,
                    shrink: shrink,
                    pattern: props.pattern,
                }}
                id={props.id}
                label={props.label}
                type={props.type}
                multiline={props.multiline}
                size={props.size}
                defaultValue={props.defaultValue}
                maxRows={props.maxRows}
                rows={props.rows}
                disabled={props.disabled}
                variant={props.variant ? props.variant : 'outlined'}
                placeholder={props.placeholder}
                onKeyPress={props.onKeyPress}
                error={props.error}
                helperText={props.helperText}
            />
        </div>
    )
}

export default CustomInput
