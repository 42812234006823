import moment from 'moment'
import { addLeadZero } from './index'

const getTimeInterval = (starts, ends, serverTimeSocket) => {
    if (moment(serverTimeSocket).isBefore(moment(starts))) {
        let duration = moment.duration(moment(starts).diff(moment(serverTimeSocket)))
        let timer = {}
        timer.days = addLeadZero(duration.days(), 2)
        timer.hours = addLeadZero(duration.hours(), 2)
        timer.minutes = addLeadZero(duration.minutes(), 2)
        timer.seconds = addLeadZero(duration.seconds(), 2)
        timer.text = 'Live Auction Starts In:'
        timer.isCompleted = false
        return timer
    } else if (
        moment(serverTimeSocket).isSameOrAfter(moment(starts)) &&
        moment().isBefore(moment(ends))
    ) {
        //console.log("working2==>",ends)
        let duration = moment.duration(moment(ends).diff(moment(serverTimeSocket)))
        let timer = {}
        //console.log("duration===>",duration)
        timer.days = addLeadZero(duration.days(), 2)
        timer.hours = addLeadZero(duration.hours(), 2)
        timer.minutes = addLeadZero(duration.minutes(), 2)
        timer.seconds = addLeadZero(duration.seconds(), 2)
        timer.text = 'Live Auction Ends In:'
        timer.isCompleted = false
        return timer
    } else {
        //console.log("working3")
        let timer = {}
        timer.isCompleted = true
        return timer
    }
}

export default getTimeInterval
