import axios from '../Utility/axiosconfig'

const updateFav = async (body, isFav) => {
    let { data } = await axios.post(
        `mobileapi/${isFav == 0 ? 'watchlist' : 'removeFwatchlist'}`,
        body,
    )
    // console.log('data====>', data && data.success === true)
    // console.log('data====>', data && data.msg === 'success')
    // console.log('data===>', (data && data.success === true) || (data && data.msg === 'success'))
    if ((data && data.success === true) || (data && data.msg === 'success')) {
        if (isFav == 1) {
            return 'Removed from Favorite'
        } else {
            return 'Added to Favorite'
        }
    } else {
        return ''
    }
}

export default updateFav
